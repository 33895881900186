import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import seoData from "../seoData/VIsitorManagementSystem.json";
import visitor from "../assets/image/solution/visitor.png";
import AboutNIne from "../component/about/AboutNIne";
import AboutTen from "../component/about/AboutTen";
import AboutEleven from "../component/about/AboutEleven";
import { Link } from "react-router-dom";

const VisiterManageSystem = () => {
  return (
    <>
      <SEO {...seoData} />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne
          title="IN OUT(Visitor Management System)"
          page="IN OUT(Visitor Management System)"
        />
        <div className="">
          <Link
            className="button-wiggle position-fixed demo_btn rounded-pill"
            to="#"
            onClick={() =>
              window.open("https://visitordemo.itcodeinfotech.com/", "_blank")
            }
          >
            Quick Demo
          </Link>
        </div>

        <div className="section section-padding">
          <div className="container">
            <div className="row">
              <div className="mx-auto col-xl-9 col-lg-9">
                <div
                  className="contact-form-box shadow-box p-4 text-center pt-0"
                  style={{ border: "2px dashed #5cb3e3" }}
                >
                  {/* <h3 className="title">Contact our recruitment team</h3>
                  <p>
                    Fill up the form and our Team will get back to you within 24
                    hours.
                  </p> */}
                  <img src={visitor} alt="visitor" className="" />
                </div>
              </div>
            </div>
          </div>
          <ul className="list-unstyled shape-group-12">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                alt="Circle"
              />
            </li>
          </ul>
        </div>
        <AboutNIne />
        <AboutTen />
        <AboutEleven />
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default VisiterManageSystem;
