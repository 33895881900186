import React from "react";
import { Link } from "react-router-dom";
import Tilty from "react-tilty";
import SectionTitle from "../../elements/section-title/SectionTitle";
import passmanage from "../../assets/image/solution/passsystem.png";
import dashboard from "../../assets/image/solution/ademindashboard.png";
import employee from "../../assets/image/solution/Employeemanage.png";
import visitormanage from "../../assets/image/solution/visitormanage.png";
import multilanguage from "../../assets/image/solution/multilanguage.png";

const AboutNine = () => {
  const features = [
    {
      title: "Visitor Pass System",
      image: passmanage,
      isImageLeft: true,
      description: [
        "The visitor pass system streamlines visitor information collection by capturing essential details such as name, email, purpose of visit. This initial process ensures all visitors are registered efficiently.",
        "Once details are entered, the system generates a visitor pass, storing information securely while providing quick access for review. The pass can be printed or accessed digitally, reducing paperwork and saving time for both visitors and staff.",
        "With each pass, security and convenience are prioritized, as all necessary information is immediately available on the pass. This system promotes efficient entry management and enhances the visitor experience.",
      ],
    },
    {
      title: "Admin & Employee Dashboard",
      image: dashboard,
      isImageLeft: false,
      description: [
        "The admin and employee dashboards provide a clear view of work location status, displaying whether an employee is working from the office or remotely. This allows for easy tracking and coordination of on-site and remote teams.",
        "Employees can monitor the number of visitors scheduled for them, helping them prepare in advance. Additionally, a real-time timer tracks work hours, ensuring accountability and streamlined time management.",
        "The dashboard also includes a pre-registration feature, enabling employees to register expected visitors ahead of time. This feature enhances visitor management efficiency and ensures smooth interactions with scheduled guests.",
      ],
    },
    {
      title: "Employee Management",
      image: employee,
      isImageLeft: true,
      description: [
        "The employee management feature allows admins to track and manage employee records, including personal details and role-based access levels. This centralized approach enhances organization and data accuracy.",
        "Admins can assign specific roles and permissions to each employee, ensuring they access only the necessary features. This role-based access boosts security and operational control within the system.",
        "Additionally, employees can update their profiles, manage work hours, and check attendance records. This empowers employees to oversee their own data while reducing admin workload.",
      ],
    },
    {
      title: "Visitor Management",
      image: visitormanage,
      isImageLeft: false,
      description: [
        "The visitor management feature enables admins to log and monitor all visitor details, including names, visit purposes, and contact information. This creates a secure, organized record of every visitor.",
        "Admins can also track visitor check-in and check-out times, offering real-time data on visitor flow. This feature supports efficient monitoring and enhances security on the premises.",
        "Visitors can be pre-registered by employees, streamlining the check-in process. This saves time and ensures a seamless experience for both visitors and staff.",
      ],
    },
    {
      title: "Multi-Language & RTL supported",
      image: multilanguage,
      isImageLeft: true,
      description: [
        "The multi-language and RTL support feature allows each user—admin, employee, and receptionist—to access the platform in their preferred language. This ensures inclusivity and ease of use for all roles.",
        "Admins can manage language settings globally, making the system adaptable for a diverse user base. RTL support is available, ensuring a smooth experience for languages that read from right to left.",
        "Employees and receptionists can select their preferred language for their interface, enhancing accessibility. This feature simplifies communication across roles, promoting efficiency in a multilingual environment.",
      ],
    },
  ];

  const renderFeature = (feature, index) => {
    const ContentSection = () => (
      <div className="col-xl-5 col-lg-6">
        <div className="case-study-featured mb-0">
          <div className="section-heading heading-left mb-0">
            <span className="subtitle">Core Feature</span>
            <h2 className="title">{feature.title}</h2>
            {feature.description.map((paragraph, i) => (
              <p key={i}>{paragraph}</p>
            ))}
          </div>
        </div>
      </div>
    );

    const ImageSection = () => (
      <div className="col-xl-7 col-lg-6 px-lg-5 px-2">
        <div className="case-study-featured-thumb text-start">
          <Tilty perspective={2000} reset={false}>
            <img
              src={feature.image}
              alt="travel"
              style={{
                borderRadius: "15px",
                boxShadow: "0px 0px 6px -2px #00000063",
              }}
            />
          </Tilty>
        </div>
      </div>
    );

    return (
      <div
        key={index}
        className="section section-padding case-study-featured-area"
      >
        <div className="container">
          <div className="row">
            {feature.isImageLeft ? (
              <>
                <ImageSection />
                <ContentSection />
              </>
            ) : (
              <>
                <ContentSection />
                <ImageSection />
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="">
        <div className="container">
          <div className="section-heading text-start mb-0">
            <h2 className="title">Core Feature</h2>
            <p className="w-100">
              Our website offers a comprehensive visitor pass management system
              with essential features like admin and employee dashboards,
              employee and visitor management, and multi-language with RTL
              support, ensuring seamless access control and user adaptability.
            </p>
          </div>
        </div>
      </div>
      {features.map((feature, index) => renderFeature(feature, index))}
    </>
  );
};

export default AboutNine;
